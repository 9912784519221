<template>
  <NuxtLink :to="`${routes.locations.path}/${location?.id}`" class="location">
    <div
      :class="
        carouselCard ? 'location__img-container--carousel' : 'location__img-container--banner'
      ">
      <nuxt-img
        v-if="location?.imageUrl"
        :src="location.imageUrl"
        :alt="location?.name ?? 'Location name'"
        format="webp"
        placeholder
        densities="x1 x2"
        :fetchpriority="carouselCard ? 'auto' : 'high'"
        fit="cover"
        class="img" />
    </div>
    <div class="location__clipped-card" :class="`location__clipped-card--${opening?.status}`">
      <div class="location__card" :class="`location__card--${opening?.status}`">
        <div class="location__card__title">{{ location?.name }}</div>
        <ImagesSvg icon="circle" width="8.2" height="8" class="icon" />
        <div class="location__card__text">
          <div v-if="opening?.status === 'open'">
            {{ text }}
          </div>
          <div v-else-if="opening?.status === 'later'">
            <span>{{ contentStore.content?.closed }}</span>
            {{ text }}
          </div>
          <div v-else class="d-flex">
            <span>{{ contentStore.content?.closed }}</span>
            <template v-if="location?.openTomorrowFrom">{{ text }}</template>
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { LocationCardApiModel } from '@groenhart/api';

const props = defineProps<{
  location: LocationCardApiModel | undefined;
  carouselCard: boolean;
}>();

const contentStore = useContentStore();

const { text, opening } = useOpeningTime(props.location ?? {});
</script>

<style lang="scss" scoped>
.location {
  $this: '.location';
  text-decoration: none;

  &__img-container--banner {
    width: 100%;
    height: px(153);

    .img {
      @include image-fit;
    }
  }

  &__img-container--carousel {
    width: px(248);
    height: px(153);

    .img {
      @include image-fit;
      border-radius: px(8);
    }
  }

  &__clipped-card {
    background-color: $c-orange-1;
    position: relative;
    margin-top: px(-29);
    margin-left: 4%;
    height: px(64);
    width: px(228);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 35%, 90% 0);
    border-radius: px(8);

    #{$this}__card {
      position: absolute;
      top: px(2);
      left: px(2);
    }

    &--open {
      background-color: $c-green-3;
    }
  }

  &__card {
    background-color: $c-green-3;
    border-radius: px(8);
    padding: px(7) px(13);
    color: $c-white;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 35%, 90% 0);
    height: px(60);
    width: px(224);

    &__title {
      font-size: px(14);
    }

    &--open {
      background-color: $c-green-3;

      .icon {
        color: $c-green-2;
      }
    }

    &--closed,
    &--later {
      color: $c-black;
      background-color: $c-white;

      .icon {
        color: $c-orange-1;
      }

      span {
        color: $c-orange-1;
        margin-right: px(4);
      }
    }

    &__text {
      display: inline-block;
      padding-left: px(10);
      font-size: px(12);
    }
  }

  .clippy {
    &__inner {
    }
  }
}
</style>
